<template>
  <aForm :form="form" @submit="handleSubmit">
    <a-row :gutter="20">
      <aCol :span="24">
        <a-form-item class="travel-input-outer">
          <label for :class="'filled'"> Nome do item </label>

          <a-input
            class="travel-input"
            placeholder="Escreva"
            v-decorator="[
              `name`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input-outer">
          <label for :class="'filled'"> Status </label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            v-decorator="[
              `status`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of status"
              :key="index"
              :value="item.value"
              :txt="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol :span="12">
        <a-form-item class="travel-input-outer">
          <label for :class="'filled'"> Ordem do item </label>

          <a-input
            class="travel-input"
            type="number"
            placeholder="0,1,2,3..."
            v-decorator="[
              `item_order`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </aCol>

      <aCol :span="24">
        <CategoryEntryNotesCustomFields
          v-if="category.id == 21 || category.id == 19"
          :form="form"
          :category="category"
        />

        <CategoryEntryAirportsCustomFields
          v-if="category.id == 30"
          :form="form"
          :category="category"
        />

        <CategoryEntryEventsModulesCustomFields
          v-if="category.id == 38"
          :form="form"
          :category="category"
        />

        <CategoryEntryExchangeCustomFields
          v-if="category.id == 36"
          :form="form"
          :category="category"
        />
      </aCol>

      <aCol class="a-center mt-20" :span="24">
        <a-button
          size="large"
          type="primary"
          :loading="loadingCreateCategory"
          html-type="submit"
        >
          CRIAR ITEM
        </a-button>
      </aCol>
    </a-row>
  </aForm>
</template>

<script>
// components
import CategoryEntryNotesCustomFields from "../sections/CategoryEntryNotesCustomFields.vue";
import CategoryEntryAirportsCustomFields from "../sections/CategoryEntryAirportsCustomFields.vue";
import CategoryEntryExchangeCustomFields from "../sections/CategoryEntryExchangeCustomFields.vue";
import CategoryEntryEventsModulesCustomFields from "../sections/CategoryEntryEventsModulesCustomFields.vue";

export default {
  name: "EditEntryDrawer",
  props: {
    category: Object,
  },
  components: {
    CategoryEntryNotesCustomFields,
    CategoryEntryAirportsCustomFields,
    CategoryEntryExchangeCustomFields,
    CategoryEntryEventsModulesCustomFields,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loadingCreateCategory: false,
      status: [
        { label: "Ativo", value: "Ativo" },
        { label: "Desativado", value: "Desativado" },
      ],
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.category_id = this.category.id;

        if (!err) {
          this.loadingCreateCategory = true;

          this.$http
            .post("/category-entry/create", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingCreateCategory = false;
              this.openCreateCategory = false;
              this.$emit("onCreateCategoryEntry");
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingCreateCategory = false;
              this.openCreateCategory = false;
            });
        }
      });
    },
  },
};
</script>
